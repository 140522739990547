import { ref, readonly } from 'vue';

const isShow = ref(false);

const openPopup = function (): void {
  isShow.value = true;
};

const closePopup = function (): void {
  isShow.value = false;
};

export default function useLoaderPopup() {
  return {
    show: readonly(isShow),
    openPopup,
    closePopup,
  };
}
